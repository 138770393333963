.root {
  width: max-content;
  flex-shrink: 0;
}

.label {
  font-weight: 500;
}

.menu__item:hover {
  background-color: var(--mantine-color-primary-outline-hover);
}
