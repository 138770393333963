.news {
  display: grid;
  grid-template-rows: 17.5rem 1fr;
  height: 100%;
}

.news__img {
  position: relative;
  display: block;
  width: 100%;
  max-height: 17.5rem;
}

.default__img {
  object-position: center 70%;
}

.news__content {
  position: relative;
  width: calc(100% - 3rem);
  margin-top: -3rem;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border: 1px solid var(--color-border);
}

.news__date {
  flex-basis: 60px;
  font-size: var(--font-lg);
  color: #7e7e7e;
  line-height: 1.3;
  text-align: center;
}

.news__date span {
  font-size: calc(var(--font-xsm) - 2px);
  color: #000;
  display: block;
}

.news__title {
  color: #222;
  font-size: var(--font-sm);
  font-weight: 600;
  line-height: 1.3;
  transition: var(--transition);
}

.news__img:hover ~ .news__content .news__title,
.news__title:hover {
  color: var(--color-text-primary);
  text-decoration: underline;
}

.news__anons,
.news__content p {
  font-size: var(--font-sm);
  font-weight: lighter;
  line-height: 1.2;
}

.news__anons:hover {
  text-decoration: underline;
}

.news__footer {
  margin-top: auto;
}

.news__type,
.news__footer a {
  color: var(--color-text-primary);
}

.news__type:hover,
.news__footer a:hover {
  text-decoration: underline;
}

.news__type {
  font-size: var(--font-sm);
}

.news__footer a {
  font-size: var(--font-xsm);
}

@media (min-width: $breakpoint-xl) {
  .news {
    max-width: 32.5rem;
  }
}
