.button {
  display: grid;
  place-items: center;
  width: 50px;
  height: 50px;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  opacity: 0.7 !important;
  transition: var(--transition);
}

.button:hover {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  opacity: 1 !important;
}

.button.active {
  opacity: 1 !important;
}

@media (max-width: $breakpoint-md) {
  .button {
    width: 42px;
    height: 42px;
    border-radius: 4px;
    opacity: 1 !important;
  }

  .button svg {
    transform: scale(0.8);
  }
}
