.header {
  border-bottom: 1px solid var(--mantine-color-gray-3);
}

.question__mark {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 14px;
  background-color: var(--color-secondary);
  color: #fff;
}

.drawer__close {
  margin-inline-start: 0;
}

.drawer__close:hover {
  background-color: transparent;
}

.drawer__close svg {
  width: 36px;
  height: 36px;
  fill: var(--color-secondary);
}

.input:focus {
  border-color: var(--color-secondary);
}

.input[data-error='true'] {
  border-color: var(----mantine-color-error);
}

.input:disabled {
  opacity: 0.9;
  background-color: #fff;
}

.footer {
  border-top: 1px solid var(--mantine-color-gray-3);
}

/* notification */

.notification__description {
  color: #fff;
}

.notification__icon {
  background-color: transparent;
}

.notification__icon.light svg path {
  fill: #fff;
}

.notification__close svg {
  fill: var(--color-secondary);
  opacity: 0.8;
  transition: var(--transition);
}

.notification__close:hover svg {
  opacity: 1;
}

.notification__close.light svg {
  fill: #fff;
}

/* mobile handler */

.mobile__handler {
  width: 50px;
  height: 50px;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.mobile__handler svg path {
  fill: var(--color-primary);
}

@media (max-width: $breakpoint-md) {
  .mobile__handler {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }

  .mobile__handler svg {
    width: 18px;
    height: 18px;
  }
}
