.control,
.dots {
  width: 50px;
  height: 50px;
  border-radius: 0;
  background-color: #f0f0f0 !important;
  font-size: var(--font);
  border: none;
  transition: var(--transition);
  user-select: none;
}

.control:hover {
  background-color: #e4e4e4 !important;
}

.control[data-active='true'],
.control[data-active='true']:hover {
  background-color: var(--color-primary) !important;
  color: #fff;
  pointer-events: none;
}

.control[data-disabled='true']:hover {
  background-color: #f0f0f0 !important;
}
