.accessibility {
  display: grid;
  place-items: center;
}

.accessibility.mobile {
  display: none;
}

.accessibility.mobile .trigger {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: #f1f3f5;
}

.trigger svg {
  fill: var(--color-secondary);
}

.accessibility.mobile .trigger svg {
  opacity: 0.8;
  transform: scale(0.9);
}

.drawer__close {
  margin-inline-start: 0;
}

.drawer__close:hover {
  background-color: transparent;
}

.drawer__close svg {
  width: 36px;
  height: 36px;
  fill: var(--color-secondary);
}

/* themes */

.theme__box {
  display: grid;
  place-items: center;
  width: 45px;
  height: 45px;
  background-color: var(--color-secondary) !important;
  border-radius: 5px;
  transition: var(--transition);
  opacity: 0.8;
}

.theme__box:hover {
  opacity: 1;
}

.theme__box.active {
  background-color: var(--color-primary) !important;
  opacity: 1;
}

.theme__box svg {
  width: 24px;
  height: 24px;
  fill: #fff;
}

/* font size controller */

.title {
  font-size: 16px;
}

.markLabel {
  display: none;
}

.label {
  top: calc(100% + 0.6rem);
  background-color: var(--color-secondary) !important;
}

.label::before {
  position: absolute;
  content: '';
  width: 6px;
  height: 6px;
  background-color: var(--color-secondary);
  bottom: calc(100% - 3px);
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  z-index: -1;
}

/* google translate */
.google__translate__input {
  height: 2.6rem;
}

.google__translate__input:focus {
  border-color: var(--color-secondary);
}

.google__translate__input + div svg {
  fill: var(--mantine-color-gray-4);
}

.google__translate__input:focus + div svg {
  fill: var(--color-secondary);
}

.concentrated_field {
  background: transparent;
  box-shadow: 0 0 0 9999999px #0009;
  filter: blur(5px);
  height: 125px;
  left: 0;
  overflow: hidden;
  padding: 80px;
  pointer-events: none;
  position: fixed;
  transform: translateY(-50%);
  width: 100%;
  z-index: 999999;
}

@media (max-width: $breakpoint-lg) {
  .accessibility.mobile {
    display: grid;
  }
}
