.root {
  flex-basis: 17.5rem;
}

.root__datepicker {
  flex-basis: 10rem;
}

.input,
.select {
  font-size: 14px;
  border-radius: 0;
  height: var(--input-height-md);
}

.input::placeholder,
.select::placeholder {
  text-transform: capitalize;
}

.select:focus {
  border-color: var(--mantine-color-gray-4);
}

.input:focus,
.select[data-expanded='true'] {
  border-color: var(--color-secondary);
}

.label {
  color: #131523;
  font-weight: 400;
  font-size: var(--font-xsm);
}

.submit__btn {
  flex-shrink: 0;
  padding-left: var(--gap-sm);
  padding-right: var(--gap-sm);
}

.submit__btn svg {
  fill: #fff;
}

.select__icon {
  fill: var(--mantine-color-gray-4);
  cursor: pointer;
}

.select__icon svg {
  width: 18px;
}

.select[data-expanded='true'] + .select__icon {
  fill: var(--color-secondary);
}

.news {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  column-gap: var(--gap);
  row-gap: var(--gap-md);
  justify-items: center;
}

.news > article {
  width: 100%;
  max-width: 22.5rem;
  grid-template-rows: 13.75rem 2fr;
}

@media (max-width: $breakpoint-md) {
  .root,
  .root__datepicker {
    flex-basis: 100%;
  }

  .submit__btn {
    margin-left: auto;
  }

  .news {
    grid-template-columns: 100%;
  }
}
