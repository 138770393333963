.date {
  font-size: var(--font);
}

.date a,
.date span {
  color: var(--color-primary);
}

.date a {
  font-weight: 500;
}

.carousel {
  width: 100%;
  max-width: 43.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--gap);
  padding-bottom: var(--gap-md);
}

.carousel__item {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 28.125rem;
}

.image {
  width: 43.75rem;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
}

.image.bordered {
  border: 1px solid #013d8c52;
  padding: 1px;
}

.carousel__nextBtn,
.carousel__prevBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding-right: 5px;
  border-radius: 50%;
  background-color: var(--mantine-color-gray-1);
}

.carousel__nextBtn:hover,
.carousel__prevBtn:hover {
  background-color: var(--mantine-color-gray-2);
  transition: var(--transition);
}

.carousel__nextBtn svg,
.carousel__prevBtn svg {
  width: 100%;
  height: 100%;
}

.carousel__nextBtn svg path,
.carousel__prevBtn svg path {
  stroke: var(--mantine-color-gray-6);
}

.carousel__nextBtn {
  transform: translateY(-50%) rotate(180deg);
  left: calc(100% + var(--gap));
}

.carousel__prevBtn {
  right: calc(100% + var(--gap));
}

.carousel ul li button::before {
  color: var(--mantine-color-gray-6);
  font-size: 8px;
}

.carousel ul {
  top: calc(100% + var(--gap-sm));
}

.pill {
  background-color: var(--mantine-color-gray-1);
}

.pill:hover {
  background-color: var(--mantine-color-gray-2);
}

.pill__label {
  cursor: pointer;
  font-size: var(--font-xsm);
  color: #001f47;
}

.carousel-with-print {
  display: none;
}

@media (max-width: $breakpoint-3xl) {
  .carousel {
    max-width: 31.25rem;
  }

  .carousel__item {
    min-height: 21.875rem;
  }

  .image {
    width: 31.25rem;
  }

  .carousel__nextBtn,
  .carousel__prevBtn {
    width: 40px;
    height: 40px;
  }

  .carousel__nextBtn {
    left: calc(100% + var(--gap-sm));
  }

  .carousel__prevBtn {
    right: calc(100% + var(--gap-sm));
  }

  .carousel ul li button::before {
    font-size: 6px;
  }
}

@media (max-width: $breakpoint-lg) {
  .carousel {
    max-width: 25rem;
  }

  .carousel__item {
    min-height: 15.625rem;
  }

  .image {
    width: 25rem;
  }
}

@media (max-width: $breakpoint-md) {
  .carousel {
    max-width: 20rem;
  }

  .carousel__item {
    min-height: 12.5rem;
  }

  .image {
    width: 20rem;
  }
}

@media (max-width: $breakpoint-sm) {
  .carousel__nextBtn,
  .carousel__prevBtn {
    display: none;
  }
}

@media print {
  .carousel-without-print {
    display: none;
  }

  .carousel-with-print {
    display: block;
  }
}
