.poll__radio {
  border-bottom: 1px solid var(--color-border);
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  transition: var(--transition);
}

.poll__radio:hover {
  border-bottom-color: var(--color-secondary);
}

.poll__radio__body {
  align-items: center;
}

.poll__label {
  font-size: var(--font-md);
  color: #666;
  line-height: 1.2;
}

.backToVote__btn {
  padding: 0.8rem 0;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  opacity: 0.8;
}

.backToVote__btn:hover {
  opacity: 1;
}

.backToVote__btn svg {
  transform: rotate(180deg);
}

.backToVote__btn svg path {
  stroke: #000;
}

.poll__btn {
  padding: 0.8rem var(--gap-xl);
  background-color: var(--color-primary) !important;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  border: 1px solid var(--color-primary);
  border-radius: var(--radius-input);
  transition: var(--transition);
}

.poll__btn:disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.poll__btn:hover {
  background-color: transparent !important;
  color: var(--color-primary);
}

.poll__btn.outline {
  background-color: transparent !important;
  color: var(--color-primary);
}

.poll__btn.outline:hover {
  background-color: var(--color-primary) !important;
  color: #fff;
}

@media (max-width: $breakpoint-md) {
  .poll__btn {
    width: 100%;
    text-align: center;
  }
}
