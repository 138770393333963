.bell_icon {
  position: absolute;
  top: -18px;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid #fff;
  border-radius: 50%;
}

.bell_icon svg {
  fill: #fff;
  transform-origin: 50% 50%;
  animation: shake 2s linear infinite alternate;
}

.button__icon {
  width: 24px;
  height: 24px;
  fill: #888;
}

.button__icon.light {
  fill: #fff;
}

.button__icon.sm {
  width: 18px;
  height: 18px;
}

@keyframes shake {
  0% {
    transform: rotateZ(0);
  }
  10% {
    transform: rotateZ(-20deg);
  }
  20% {
    transform: rotateZ(15deg);
  }
  30% {
    transform: rotateZ(-10deg);
  }
  40% {
    transform: rotateZ(7.5deg);
  }
  50% {
    transform: rotateZ(-6deg);
  }
  60% {
    transform: rotateZ(5deg);
  }
  70% {
    transform: rotateZ(-4.2deg);
  }
  80% {
    transform: rotateZ(3.75deg);
  }
  90% {
    transform: rotateZ(-3.3deg);
  }
  100% {
    transform: rotateZ(0);
  }
}
