.input {
  color: #212529;
  padding: 1.5rem var(--gap);
  border: 1px solid var(--color-border);
  border-radius: var(--radius-input);
  transition: var(--transition);
}

.input::placeholder {
  color: #787d83;
}

.input:focus {
  border-color: var(--color-secondary);
}

.label {
  color: #131523;
  font-weight: 400;
  font-size: var(--font-xsm);
}

.btn__icon svg {
  fill: #fff;
  transform: scale(0.9);
}
