.paper {
  box-shadow: var(--box-shadow);
}

.title {
  color: var(--color-text-primary);
  font-size: var(--font-lg);
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 0.5rem;
}

.updatedAt {
  font-size: var(--font-xsm);
  color: #666;
  align-self: flex-end;
}

.print--btn {
  border: 1px solid #cc950f;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  display: grid;
  place-items: center;
  transition: var(--transition);
}

.print--btn svg {
  stroke: #cc950f;
  stroke-width: 3px;
}

.print--btn:hover {
  background-color: #cc950f;
}

.print--btn:hover svg {
  stroke: #fff;
}

.age__limit {
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  color: #cc950f;
  border: 1px solid #cc950f;
  font-size: 12px;
  font-weight: 600;
  border-radius: 50%;
}

@media print {
  .paper {
    box-shadow: none;
    padding: 1.5cm;
  }

  .print--btn {
    display: none;
  }

  @page {
    margin: 1.5cm;
  }
}

@media (max-width: $breakpoint-md) {
  .title {
    text-transform: none;
  }
}
