.wrapper {
  border: 1px solid #bcbcbc;
}

.employee {
  display: grid;
  grid-template-columns: 1fr 2fr 30%;
  align-items: center;
}

.inner {
  grid-template-columns: 1fr 4fr;
}

.employee__img {
  flex-shrink: 0;
  position: relative;
  width: 11.875rem;
  height: 11.875rem;
  background-color: #e5e8eb;
  border-radius: 50%;
  overflow: hidden;
}

.employee__img img {
  object-fit: cover;
}

.employee__fullname {
  font-size: var(--font-lg);
  font-weight: 600;
}

.employee__position {
  font-size: var(--font-sm);
  max-width: 31.25rem;
  padding-top: var(--gap-sm);
}

.employee__info {
  gap: var(--gap-sm);
  flex-direction: column;
}

.employee__info a,
.employee__info .info {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.employee__info .info {
  align-items: flex-start;
}

.employee__info .info__icon {
  flex-shrink: 0;
  width: 24px;
  fill: var(--color-text-primary);
}

.employee__info .info__icon.phone__icon {
  width: 20px;
}

.info__icon.address__icon {
  width: auto;
}

.employee__info i {
  font-size: var(--font-sm);
  color: #000;
}

.employee__functionalities {
  justify-content: flex-start;
}

/* modal */

.modal__body {
  position: relative;
  overflow: hidden;
}

.modal__body::after {
  content: '';
  position: absolute;
  bottom: -20%;
  right: -5%;
  width: 640px;
  height: 640px;
  background: url('/img/modal_pattern.png');
}

.modal__title,
.modal__fullname {
  position: relative;
  width: max-content;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
}

.modal__title {
  color: #444;
  text-transform: uppercase;
}

.modal__fullname {
  color: var(--color-text-primary);
}

.modal__title span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  width: 100%;
  max-width: 150px;
  height: 20px;
}

.left__line {
  right: calc(100% + var(--gap));
}

.left__line::before,
.left__line::after,
.right__line::before,
.right__line::after {
  position: absolute;
  content: '';
  background-color: #043b87;
  height: 1px;
}

.left__line::before,
.left__line::after {
  right: 0;
}

.right__line::before,
.right__line::after {
  left: 0;
}

.left__line::before,
.right__line::before {
  width: 75%;
  top: 6px;
}

.left__line::after,
.right__line::after {
  width: 100%;
  bottom: 6px;
}

.right__line {
  left: calc(100% + var(--gap));
}

.modal__close {
  width: 32px;
  height: 32px;
  margin-inline-start: 0;
}

.modal__close svg {
  fill: var(--color-secondary);
}

.modal__close:hover {
  background-color: transparent;
}

.modal__content {
  position: relative;
  font-size: 14px;
  color: #575757;
  padding-bottom: 0;
  z-index: 2;
}

.modal__content p {
  line-height: 1.6;
  padding-top: 8px;
}

@media (max-width: $breakpoint-3xl) {
  .employee {
    grid-template-columns: 100%;
    place-items: center;
  }

  .employee__img {
    width: 10rem;
    height: 10rem;
  }

  .employee__fullname,
  .employee__position {
    text-align: center;
  }

  .employee__info {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: var(--gap-md);
    justify-content: center;
    align-items: baseline;
  }

  .employee__functionalities {
    justify-content: center;
  }
}

@media (max-width: $breakpoint-sm) {
  .modal__title {
    padding-left: 32px;
  }

  .modal__title span {
    display: none;
  }
}
