.drawer__close {
  margin-inline-start: 0;
}

.drawer__close:hover {
  background-color: transparent;
}

.drawer__close svg {
  width: 36px;
  height: 36px;
  fill: var(--color-secondary);
}

.title {
  font-size: 16px;
}

.question__mark {
  width: 28px;
  height: 28px;
  background-color: var(--color-primary);
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  transition: 0.1s linear;
}

.question__mark:hover {
  box-shadow: 0 0 5px 5px rgba(107, 107, 107, 0.2);
}

/* trigger */

.trigger {
  width: 32px;
  height: 32px;
  display: grid;
  place-items: center;
  background-color: #1351a0;
  color: #fff;
  border-radius: 4px;
  border: none;
  outline: none;
  user-select: none;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  transform: scale(0);
  transition: 0.2s ease-in-out;
}

.trigger.active {
  opacity: 1;
  pointer-events: all;
  transform: scale(1);
}

.trigger.loading {
  cursor: default;
}

.trigger.playing {
  animation: audioPlaying 1.6s linear infinite;
  -webkit-animation: audioPlaying 1.6s linear infinite;
  -moz-animation: audioPlaying 1.6s linear infinite;
  -o-animation: audioPlaying 1.6s linear infinite;
}

.trigger.playing:hover {
  animation: none;
  -moz-animation: none;
  -webkit-animation: none;
  -o-animation: none;
}

@keyframes audioPlaying {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes audioPlaying {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes audioPlaying {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes audioPlaying {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

/* notification */

.notification__description {
  color: #fff;
}

.notification__icon {
  background-color: transparent;
}

.notification__close svg {
  fill: var(--color-secondary);
  opacity: 0.8;
  transition: var(--transition);
}

.notification__close:hover svg {
  opacity: 1;
}
