.link {
  position: absolute;
  transform: translateY(-150%);
  background-color: var(--color-primary);
  color: #fff;
  transition: var(--transition);
  padding-inline: var(--gap);
  z-index: 2;
}

.link:focus {
  transform: translateY(0%);
}
