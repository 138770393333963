.box {
  border: 1px solid var(--color-border);
  transition: var(--transition);
}

.box:hover {
  border-color: var(--color-text-primary);
}

.box h3 {
  font-size: var(--font-sm);
  font-weight: bold;
  transition: var(--transition);
}

.box:hover h3 {
  color: var(--color-text-primary);
}

.box p {
  font-size: calc(var(--font-xsm) - 2px);
}

.box svg {
  fill: #c2c2c2;
}
