.menu {
  position: sticky;
  top: calc(var(--navbar-height) + var(--gap)); /* navbar's height + gap */
  height: max-content;
  min-width: 18.75rem;
  max-height: 70vh;
  overflow-y: auto;
  background-color: #f0f0f0;
}

.menu::-webkit-scrollbar {
  width: 5px;
}

.menu::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.menu::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 1px;
}

.menu::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.menu__skeleton {
  height: 31.25rem;
}

.menu__item {
  display: flex;
  align-items: center;
  gap: var(--gap-sm);
  padding: var(--gap-sm) var(--gap);
  font-size: calc(var(--font-md) + 2px);
  line-height: 1.3;
  color: #6b6b6b;
}

.menu__item:hover {
  background-color: #e6e6e6;
}

.menu__item.active {
  background-color: var(--color-primary);
  color: #fff;
}

.menu__item.active svg path {
  stroke: #fff;
}

.menu__item.disabled {
  pointer-events: none;
}

.menu__item span {
  flex-shrink: 0;
}

.menu__item svg {
  width: 32px;
  height: max-content;
  transform: rotate(180deg);
}

.menu__item svg path {
  stroke: var(--color-primary);
}

.menu__btn {
  height: max-content;
  background-color: #e9e9e9;
  color: #848484;
  padding: var(--gap-sm) var(--gap);
  font-size: calc(var(--font-md) + 2px);
  font-weight: 400;
  line-height: 1.3;
  outline: none;
}

.menu__btn:hover {
  background-color: #e9e9e9;
  color: #848484;
}

.menu__btn__label {
  white-space: normal;
  text-align: left;
}

.btn__icon path {
  fill: #848484;
}

.btn__icon.active {
  transform: rotate(180deg);
}

@media (max-width: $breakpoint-xl) {
  .menu {
    position: static;
    height: 100%;
    min-width: 100%;
    max-height: 100%;
    overflow-y: visible;
  }

  .menu__skeleton {
    height: 3rem;
  }
}
