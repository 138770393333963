.warning {
  font-size: var(--font-xsm);
}

.warning b {
  color: #fb6952;
}

.label {
  font-size: var(--font);
  color: var(--color-secondary);
  font-weight: 600;
}

.input {
  font-size: var(--font-sm);
  height: 100%;
}

textarea.input {
  min-height: 12rem;
}

.input[aria-invalid='false']:focus {
  border-color: var(--color-secondary);
}

.error {
  font-size: calc(var(--font-xsm) - 2px);
}

/* notification */

.notification__description {
  color: #fff;
}

.notification__icon {
  background-color: transparent;
}

.notification__icon.light svg path {
  fill: #fff;
}

.notification__close svg {
  fill: var(--color-secondary);
  opacity: 0.8;
  transition: var(--transition);
}

.notification__close:hover svg {
  opacity: 1;
}

.notification__close.light svg {
  fill: #fff;
}
