.root {
  width: 100%;
}

.input {
  color: #212529;
  padding: 1.5rem var(--gap);
  padding-right: calc(var(--gap) + var(--gap) + 34px); /* 34px is the width of the search icon */
  border: 1px solid var(--color-border);
  border-radius: var(--radius-input);
  transition: var(--transition);
}

.input::placeholder {
  color: #383d42;
}

.input:focus-within {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
}

.root.withinModal .input {
  border-radius: 0;
}

.button {
  width: auto;
  height: 100%;
  top: 0;
  right: 0;
  padding-right: var(--gap);
  padding-left: var(--gap);
  cursor: pointer;
  border-radius: var(--radius-input);
  transition: var(--transition);
}

.root.withinModal .button {
  border-radius: 0;
}

.button svg {
  fill: #d2d2d2;
}

.button:hover {
  background-color: var(--color-primary);
}

.button:hover svg {
  fill: #fff;
}

.dropdown {
  border-radius: var(--radius-input);
}

.root.withinModal ~ .dropdown {
  position: relative;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  border: none;
}

.option {
  padding-top: var(--gap-sm);
  padding-bottom: var(--gap-sm);
  transition: var(--transition);
}

.option:hover {
  background-color: var(--color-primary);
  color: #fff;
}

.root.withinModal ~ .dropdown .option {
  padding: var(--gap);
}
